exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-sv-js": () => import("./../../../src/pages/index.sv.js" /* webpackChunkName: "component---src-pages-index-sv-js" */),
  "component---src-pages-products-contact-lens-solution-en-js": () => import("./../../../src/pages/products/contact-lens-solution.en.js" /* webpackChunkName: "component---src-pages-products-contact-lens-solution-en-js" */),
  "component---src-pages-products-contact-lens-solution-sv-js": () => import("./../../../src/pages/products/contact-lens-solution.sv.js" /* webpackChunkName: "component---src-pages-products-contact-lens-solution-sv-js" */),
  "component---src-pages-products-saline-solution-en-js": () => import("./../../../src/pages/products/saline-solution.en.js" /* webpackChunkName: "component---src-pages-products-saline-solution-en-js" */),
  "component---src-pages-products-saline-solution-sv-js": () => import("./../../../src/pages/products/saline-solution.sv.js" /* webpackChunkName: "component---src-pages-products-saline-solution-sv-js" */)
}

